import AppContext, { Locale } from "../appContext"
import { Ad, AdPreview, Skeleton, Typography } from "@cruto/cruto-core"
import { useContext } from "react"
import React from "react"
import Skills from "./Skills"
import classNames from "classnames"
import { OutboundLink } from "gatsby-plugin-google-gtag-optin"
import Address from "./Address"
import Occupation from "./Occupation"

type Props = {
  ad: AdPreview | null
  className?: string
}

const AdCard = ({ ad, className }: Props) => {

  if (ad) {
    return (
      <OutboundLink
        style={{ opacity: "1" }}
        href={`https://app.cruto.io/job/${ad.adId}`}>
        <div
          className={classNames(
            "move-top-on-hover p-3 rounded u-bg-white shadow",
            className
          )}
        >

          <Typography variant="h5" fontWeight="bold" style={{ wordBreak: "break-word" }}>
            {ad.headline}
          </Typography>

          <Typography variant="body1" fontWeight="bold">
            {ad.companyName}
          </Typography>

          <Address {...ad.address} />

          {
            ad.occupation &&
            <Occupation {...ad.occupation} />
          }
          <Skills skills={ad.skills} />
        </div>
      </OutboundLink>
    )
  }

  return (
    <div
      className={classNames(
        "move-top-on-hover p-3 rounded u-bg-white shadow",
        className
      )}
    >
      <Skeleton
        variant="text"
        height={45.58}
        width={"100%"}
        style={{ marginBottom: ".5rem" }}
      />
      <Skeleton
        variant="text"
        height={25.59}
        width={"100%"}
        style={{ marginBottom: "1rem" }}
      />
    </div>
  )
}

export default AdCard
