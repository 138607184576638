import React, { useMemo } from "react"
import { useContext } from "react"
import AppContext, { Locale } from "../appContext"
import { Grid, Typography, AdsPreviewConcept, concatWithSeparator } from "@cruto/cruto-core"
import StarIcon from "@material-ui/icons/Star"


type Props = {
  skills: AdsPreviewConcept[]
}

const Skills = ({ skills }: Props) => {
  const { locale } = useContext(AppContext)

  const displayString: string | null = useMemo(() => {

    if ((skills?.length ?? 0) > 0) {

      const slicedArr: AdsPreviewConcept[] = skills.slice(0, 5)

      return locale === Locale.en ? concatWithSeparator(", ", ...slicedArr.map(s => s.labelEn ?? s.label)) : concatWithSeparator(", ", ...slicedArr.map(s => s.label))
    }

    return null

  }, [locale, skills?.length])

  if (displayString) {

    return (

      <Grid container>
        <Grid item xs={12} display="flex" alignItems="center">
          <StarIcon />
          <Typography
            className="ps-2"
            variant="body2" >
            {displayString}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return null
}
export default Skills
