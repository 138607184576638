import React, { useContext, useMemo } from "react"
import firebase from "gatsby-plugin-firebase"
import "firebase/firestore"
import { useCollectionOnce } from "react-firebase-hooks/firestore"
import { AdSource, AdPreview, fromDbObj } from "@cruto/cruto-core"
import AdsPreview from "../../common/adsPreview/AdsPreviewV2"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { getClientTranslations } from "../layout/helperFunctions"
import ArrowForward from "@material-ui/icons/ArrowForward"
import AppContext from "../../common/appContext"

type Props = {
  heading: string
  body: {
    html: string
  }
}

const numOfAds = 4

const Body = ({ heading, body }: Props) => {

  const { locale } = useContext(AppContext)

  const [value, loading] = useCollectionOnce(
    firebase
      .firestore()
      .collection(`adPreviews`)
      .where("source", "==", AdSource.internalConsulting)
      .limit(numOfAds)
  )

  const ads: (AdPreview | null)[] = useMemo(() => {
    if (!loading && value) {
      return value.docs.map(e => fromDbObj(e.data()))
    }

    return new Array(numOfAds).fill(null)
  }, [loading, value])

  return (
    <>
      <div className="container py-5">
        <h3 className="pb-4">{heading}</h3>
        <div dangerouslySetInnerHTML={{ __html: body.html }} />
      </div>
      <div className="container pb-5">
        <OutboundLink
          className="start-page-section-link"
          href={`https://app.cruto.io`}
        >
          <section className={`d-flex align-items-center pb-3`}>
            <p className={`m-0 read-more`}>
              {getClientTranslations("View all assignments", locale)}
            </p>
            <ArrowForward />
          </section>
        </OutboundLink>
        <AdsPreview adSource={AdSource.internalConsulting} ads={ads} className={"col-12"} />
      </div>
    </>
  )
}

export default Body
