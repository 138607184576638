import React from "react"
import Wave, { WaveType } from "../ui/wave"
import Lottie from 'react-lottie';
import * as animationData from '../../assets/81045-rocket-launch-blue.json'


type Props = {
  heading: string
  body: {
    html: string
  }
}

const TopSection = ({ heading, body }: Props) => {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return (
    <div className="u-bg-blue pt-5">
      <section className="min-vh-50 d-flex align-items-center">
        <div className={"container"}>
          <div className="row">
            <div className="align-items-center col-12 col-md-6 d-flex order-last order-md-0 p-3">
              <div
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                className="text-center text-md-start pt-4 pt-md-0"
              >
                <h2 className={"u-c-white"}>{heading}</h2>
                <div
                  className="u-lead-md pt-3 u-c-white u-o-1"
                  dangerouslySetInnerHTML={{ __html: body.html }}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">

              <Lottie options={defaultOptions} />
            </div>
          </div>


        </div>
      </section>
      <Wave className={"u-c-white"} type={WaveType.v3} />
    </div>
  )
}

export default TopSection
