import React, { useContext } from "react"
import AdCard from "./AdCard"
import classNames from "classnames"
import { AdPreview, AdSource } from "@cruto/cruto-core"
import { getClientTranslations } from "../../components/layout/helperFunctions"
import AppContext from "../appContext"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import ArrowForward from "@material-ui/icons/ArrowForward"

type Props = {
    ads: (AdPreview | null)[]
    className?: string
    outboundLinkClassName?: string
    cardClassName?: string
    adSource?: AdSource
}

const AdsPreview = ({
    ads,
    className,
    outboundLinkClassName,
    cardClassName
}: Props) => {
    const { locale } = useContext(AppContext)

    return (
        <div
            className={classNames(
                "px-3",
                className
            )}
        >
            {ads.map((e, i) => {
                return (
                    <div key={i} className="row py-3">
                        <div className={`col-12`}>
                            <AdCard ad={e} className={cardClassName} />
                        </div>
                    </div>
                )
            })}

            <div className="row">
                <div className="col-12">
                    <div className="pt-3">
                        <OutboundLink
                            className="start-page-section-link"
                            href={`https://app.cruto.io`}
                        >
                            <section className={`d-flex align-items-center justify-content-end ${outboundLinkClassName}`}>
                                <p className={`m-0 read-more`}>
                                    {getClientTranslations("View all assignments", locale)}
                                </p>
                                <ArrowForward />
                            </section>
                        </OutboundLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdsPreview
