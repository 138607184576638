import { AdsPreviewConcept, Typography } from "@cruto/cruto-core";
import { Grid } from '@cruto/cruto-core';
import WorkIcon from "@material-ui/icons/Work"

type Props = Omit<AdsPreviewConcept, "id">

import React, { useContext, useMemo } from 'react'
import AppContext, { Locale } from "../appContext";

const Occupation = ({ label, labelEn }: Props) => {

    const { locale } = useContext(AppContext)

    const displayString: string | null = useMemo(() =>
        locale === Locale.en ?
            labelEn ?? label ?? null :
            label ?? null,
        [locale, label, labelEn])

    if (displayString) {

        return (

            <Grid container>
                <Grid item xs={12} display="flex" alignItems="center">
                    <WorkIcon />
                    <Typography
                        className="ps-2"
                        variant="body2" >
                        {displayString}
                    </Typography>
                </Grid>
            </Grid>
        )
    }


    return null

}

export default Occupation