import { concatWithSeparator, Grid, Typography } from '@cruto/cruto-core';
import React, { useMemo } from 'react'
import PlaceIcon from "@material-ui/icons/Place"

type Props = {
    region?: string;
    municipality?: string;
    postcode?: string;
    streetAddress?: string;
}

const Address = ({ region, municipality, postcode, streetAddress }: Props) => {

    const displayString: string | null = useMemo(() => {
        if (region || municipality) {


            return concatWithSeparator(", ", region, municipality)
        }

        return null
    }, [region, municipality])

    if (displayString) {

        return (

            <Grid container>
                <Grid item xs={12} display="flex" alignItems="center">
                    <PlaceIcon />
                    <Typography 
                    className="ps-2"
                    variant="body2" >
                        {displayString}
                    </Typography>
                </Grid>
            </Grid>
        )
    }


    return null
}

export default Address